<div class="dashboard-right-inner">

  <!--breadcrumb-->
  <Breadcrumb :id="$route.params.id" />
  <app-loader v-if="clubLoading"></app-loader>
  <!--dashboard-content-->
  <div class="dashboard-content">
    <div v-if="!clubLoading">
      <card class="radius-5">
        <cardHeader
          class="dashboard-title-head k-hbox p-30 p-10-vsm align-items-center bg-white justify-content-between flex-wrap">

          <div class="d-flex align-items-center justify-content-start me-4 flex-wrap w-100-vsm mb-sm-0 mb-3">
            <avatar :class="'mr-20 file-uploader-default'" type="image" :size="'large'" shape="circle"
              :rounded="'full'">
              <img v-if="clubDetails?.logo" :src="clubDetails.logo.url" alt="Logo">
              <img v-if="!clubDetails?.logo" src="../../../assets/images/club-img.png" alt="Logo">
            </avatar>

            <div>
              <cardTitle class="font-28 font-34-sm-big font-18-vsm font-w-300">
                {{clubDetails.name}}
              </cardTitle>
              <cardSubtitle :class="'font-16 font-w-400'">
                {{clubDetails.address}}
              </cardSubtitle>
            </div>
          </div>

          <cardActions>
            <!-- :disabled="this.hasPostPermission() == false && this.hasPutPermission() == false" -->
            <kbutton 
              @click="goToSettings">Settings</kbutton>
            <!-- <AppSnackBar :message="'Changes in club information were updated successfully.'" :notificationType="'success'" :snackbarOpen="true"></AppSnackBar> -->
            <!-- <div>
                      <kbutton @click="onClick" ref="button">....</kbutton>
                      <Popup :anchor="'button'" :show="show" :popup-class="'popup-content'">
                        <kbutton @click="openModal" ref="button">Edit</kbutton>
                        <kbutton @click="toggleDialog" ref="button">Delete</kbutton>
                      </Popup>
                    </div> -->
            <!-- <DropDownButton :class="'custom-dropdown-menu'" :items="actions" :icon="'more-vertical'" @itemclick="onItemClick" /> -->
            <kbutton v-if="!isClubAdmin" :theme-color="'primary'" :disabled="this.hasDeletePermission() == false"
              @click="openModal">Delete</kbutton>
          </cardActions>
        </cardHeader>

        <DeleteClubModal v-if="modalVisible" :isClub="clubDetails.isAssociation? 'Association':'Club'" />
        <!-- <edit-club-modal :pName="clubDetails.name" :pAddress="clubDetails.address" :pLogoId="clubDetails.logo?.id"
          v-if="modalVisible" @close="modalVisible=false" @editClubData="updateClub($event)" /> -->

      </card>
    </div>

    <!-- <div>
      <img v-if="clubDetails.logo" :src="clubDetails.logo.url" alt="Logo">
      <img v-if="!clubDetails.logo" src="../../../assets/images/club-img.png" alt="Logo">
      <h2>{{clubDetails.name}}</h2>
      <h2>{{clubDetails.address}}</h2>
      <kbutton>Setting</kbutton>
      <div>
        <kbutton @click="onClick" ref="button">....</kbutton>
        <Popup :anchor="'button'" :show="show" :popup-class="'popup-content'">
          <kbutton @click="openModal" ref="button">Edit</kbutton>
          <kbutton @click="toggleDialog" ref="button">Delete</kbutton>
        </Popup>
      </div> -->

    <!-- </div> -->
  </div>

</div>