<template src="./ClubDashboard.html"></template>

<script>
import DeleteClubModal from "../deleteClubModal/DeleteClubModal";
import { Popup } from "@progress/kendo-vue-popup";
import { Button } from "@progress/kendo-vue-buttons";
import EditClubModal from "../editClubModal/EditClubModal";
import Breadcrumb from "../../common/AppBreadCrumbs/BreadCrumbs.vue";
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardImage,
  CardActions,
  Avatar,
} from "@progress/kendo-vue-layout";
import { DropDownButton } from "@progress/kendo-vue-buttons";
import AppSnackBar from "../../common/AppSnackBar/AppSnackBar";
import AppLoader from "../../common/AppLoader/AppLoader.vue";

const cardsData = {
  thumbnailSrc:
    "https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/bg_flag.jpg",
  headerTitle: "Central Newcastle RLFC",
  headerSubtitle: "1901 Thornridge Cir. Shiloh, Hawaii 81063",
  commentsExpanded: false,
  postLiked: false,
  comments: [],
  newCommentTextValue: "",
  postLikes: 174,
  scrollViewItems: {
    url: "https://www.telerik.com/kendo-angular-ui-develop/components/layout/card/assets/rose_festival.jpg",
  },
};

export default {
  components: {
    DeleteClubModal,
    EditClubModal,
    Popup: Popup,
    kbutton: Button,
    card: Card,
    cardHeader: CardHeader,
    cardBody: CardBody,
    cardTitle: CardTitle,
    cardSubtitle: CardSubtitle,
    cardImage: CardImage,
    cardActions: CardActions,
    avatar: Avatar,
    DropDownButton,
    Breadcrumb,
    AppSnackBar,
    AppLoader,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
  },
  data: function () {
    return {
      isClubAdmin: false,
      club: {},
      visibleDialog: false,
      visibleEditDialog: false,
      show: false,
      cards: cardsData,
      imageUrl:
        "http://demos.telerik.com/kendo-ui/content/shared/icons/16/star.png",
      actions: ["Edit", "Delete"],
    };
  },
  computed: {
    clubDetails() {
      return this.$store.getters.clubDetails;
    },
    clubId() {
      return parseInt(this.id);
    },
    modalVisible() {
      return this.$store.state.club.deleteModalVisible;
    },
    clubLoading() {
      return this.$store.state.club.loading;
    },
  },
  mounted() {
    // this.getClub(this.clubId);
    this.show = false;
    const clubInfo = JSON.parse(window.localStorage.getItem("clubInfo"));
    if (clubInfo?.roleId === 1) {
      this.isClubAdmin = true;
    } else {
      this.isClubAdmin = false;
    }
  },
  methods: {
    hasDeletePermission() {
      return this.$hasAccessToModule({
        permissionKey: "hasDeletePermission",
        moduleCode: "dashboard",
        itemCode: null,
        childItemCode: null,
      });
    },
    toggleDialog() {
      this.visibleDialog = !this.visibleDialog;
    },
    editClubModalOpen() {
      this.visibleEditDialog = !this.visibleEditDialog;
    },
    getClub(clubId) {
      this.$store.dispatch("getClub", clubId);
    },
    onClick() {
      this.show = !this.show;
    },
    updateClub(data) {
      this.$store.dispatch("updateClub", data);
      this.show = false;
    },
    openModal() {
      this.$store.commit("DELETE_MODAL_VISIBLE", true);
    },
    // onItemClick(event) {
    //   if(event.item == "Edit"){
    //     this.openModal()
    //   }
    //   if(event.item == "Delete"){
    //      this.toggleDialog()
    //   }
    // },
    goToSettings() {
      this.$router.push("/club-details/" + this.id + "/settings");
    },
  },
};
</script>
