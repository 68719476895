<template src="./DeleteClubModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from "@progress/kendo-vue-dialogs";
import { Button } from "@progress/kendo-vue-buttons";
import { Input } from "@progress/kendo-vue-inputs";
import modal from "../../common/AppModal/AppModal";
import { deleteClubService } from "../../../services/clubDetails/club.service";

export default {
  components: {
    "k-dialog": Dialog,
    "dialog-actions-bar": DialogActionsBar,
    kbutton: Button,
    "k-input": Input,
    modal,
  },
  props: {
    isClub: {
      type: String,
    },
  },
  computed: {
    modalVisible() {
      return this.$store.state.club.deleteModalVisible;
    },
  },
  data: function () {
    return {
      visibleDialog: true,
      clubId: this.$route.params.id,
      message: ''
    };
  },
  methods: {
    cancelModal() {
      this.$store.commit("DELETE_MODAL_VISIBLE", false);
    },
    deleteClub() {
      deleteClubService(this.clubId)
        .then((response) => {
          this.$store.commit("DELETE_MODAL_VISIBLE", false);

          // this.$store.commit("CONTROL_STATUS", {
          //   type: "success",
          //   Class: "status-success",
          //   Message: "Selected club has been deleted.",
          // });

          // this.$store.commit("SHOW_MESSAGE", true);
          // setTimeout(() => {
          //   this.$store.commit("SHOW_MESSAGE", false);
          //   this.$store.commit("REMOVE_CLUB", false);
          // }, 2000);

          this.message = "Club has been deleted successfully.";
          if (this.isClub == 'Association') {
            this.message = "Association has been deleted successfully.";
          }

          let payload = {
            type: "success",
            Message: this.message,
          };
          this.$store.dispatch("showNotification", payload);


          this.$store.commit("LOADING_STATUS", false);

          this.$router.push({ name: "SystemDashboard" });
        })
        .catch((error) => { });

      // this.$store.dispatch("removeClub", this.clubId);
    },
    modalHandler(data) {
      this.$store.commit("DELETE_MODAL_VISIBLE", false);
    },
  },
};
</script>