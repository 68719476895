<div>
  <k-dialog :class="'modal-md'" :title="'Edit Club'" @close="$emit('close')">

    <k-form>

      <div class="k-form-field">
        <label>Club Logo</label>
        <div class="file-upload-wrap" :style="{ width: '100%' }">
          <div class="custom-upload-box">
            <span class="k-icon k-i-upload color-light2 font-30"></span>
            <span class="font-16 color-light2 mt-2">Click or drag the file here to upload</span>
          </div>
          <upload :files="files" :batch="false" :multiple="true" :restrictions="{allowedExtensions: [ '.png', '.jpeg', '.jpg' ]}" 
            @add="onAdd" @remove="onRemove" @progress="onProgress" @statuschange="onStatusChange"
            :with-credentials="false" :save-url="uploadDocument" :accept="[ '.png', '.jpeg', '.jpg' ]" />
        </div>
      </div>

      <div class="k-form-field">
        <label>Club Name</label>
        <k-input type="text" placeholder="club Name" v-model="club.name"></k-input>
      </div>

      <div class="k-form-field">
        <label>Club Address</label>
        <k-input type="text" placeholder="Club Address" v-model="club.address"></k-input>
      </div>

    </k-form>

    <dialog-actions-bar :class="'justify-content-end'">
      <kbutton @click="$emit('close')" :class="'flex-none'">Cancel</kbutton>
      <kbutton :theme-color="'primary'" @click='editClub' :class="'flex-none'">Save</kbutton>
    </dialog-actions-bar>

  </k-dialog>
</div>