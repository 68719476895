<template src="./EditClubModal.html"></template>

<script>
import { Dialog, DialogActionsBar } from '@progress/kendo-vue-dialogs';
import { Button } from '@progress/kendo-vue-buttons';
import { Input } from '@progress/kendo-vue-inputs';
import { Form } from '@progress/kendo-vue-form';
import { Upload } from '@progress/kendo-vue-upload';
import myUpload from 'vue-image-crop-upload';
import { configuration } from '@/configurationProvider';

export default {
  components: {
    'k-dialog': Dialog,
    'dialog-actions-bar': DialogActionsBar,
    kbutton: Button,
    'k-input': Input,
    'k-form': Form,
    upload: Upload,
    myUpload,
  },
  data: function () {
    var url = configuration.apiBaseUrl;

    return {
      visibleDialog: true,
      show: true,
      url,
      uploadDocument:
        url + '/api/v1/Document?clubId=' + this.$route.params.id,
      club: {
        id: this.$route.params.id,
        logoId: this.pLogoId,
        name: this.pName,
        address: this.pAddress,
      },
    };
  },
  props: {
    pLogoId: '',
    pName: '',
    pAddress: '',
  },
  methods: {
    editClub() {
      this.$emit('editClubData', this.club);
      this.$emit('close');
    },
    onStatusChange(event) {
      // ;
      this.club.logoId = event.response.response.id;
    },
  },
};
</script>